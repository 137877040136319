@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Raleway&display=swap");
.Container {
  width: 100%;
  height: 100vh;
}
.loginBg {
  background-color: #f7f7f7;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.loginContainers {
  width: 350px;
  background: #ffffff;
  box-shadow: 0px 4px 44px -10px rgba(0, 0, 0, 0.06);
  border-radius: 30px;
}
.loginBox {
  width: 255px;
  height: 370px;
  margin: auto;
}
.borderBottom {
  border-bottom: 1px solid #b6bfcca6;
}
.loginNames {
  padding-top: 40px;
  font-family: "Open Sans", sans-serif;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-size: 16px;
  color: #000000;
}
.logos {
  max-width: 60.5px;
  max-height: 128px;
  text-align: center;
  margin-bottom: 20px;
}
.logos2 {
  max-width: 116px;
}

.mdi-36px {
  font-size: 25px;
  margin-right: 10px;
}
.bgcontainer {
  display: flex;
  /* border-bottom: 1px solid #b6bfcca6; */
  padding-bottom: 18px;
  width: 100%;
  justify-content: space-between;
}
.appbtn {
  display: flex;
  transition: 0.6s all ease-in-out;
  border: 1px solid #a6a6a6;
  background: #ffebe5 !important;
  color: black;
  width: 130px;
  height: 38px;
  margin: 20px 3px;
  align-items: center;
}
.appbtn:hover {
  display: flex;
  border: 1px solid #a6a6a6;
  background: black !important;
  color: white;
  width: 130px;
  height: 38px;
  margin-left: 8px;
  margin-right: 8px;
}
.appstore {
  font-family: "Open Sans", sans-serif;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 8px;
  position: relative;
  top: 2px;
}

.appsto {
  font-family: "Open Sans", sans-serif;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  padding: 0;
}

.loginName {
  font-family: "Open Sans", sans-serif;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 28px;
  letter-spacing: -0.3px;
  /* padding-top: 65px; */
}

.email_para {
  font-family: "Open Sans", sans-serif;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-size: 11px;
  color: #7e8389;
  padding: 35px 0px;
  letter-spacing: 0.8px;
}

.text_para {
  font-family: "Open Sans", sans-serif;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-size: 11px;
  color: #7e8389;
  padding-bottom: 17.5px;
  letter-spacing: 0.8px;
}
.link {
  font-family: "Open Sans", sans-serif;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  color: #7e8389;
  letter-spacing: 0.8px;
}

@media screen and (max-width: 700px) and (min-width: 500px) {
  .logos2 {
    display: none;
  }
  .loginBg {
    background-color: #f7f7f7;
    width: 100%;
    height: 100%;
    align-items: center;
    display: block;
  }
  .loginContainers {
    width: 80%;
    height: 446px;
    background: #ffffff;
    box-shadow: 0px 4px 44px -10px rgba(0, 0, 0, 0.06);
    border-radius: 30px;
    margin: auto;
  }
  .loginBox {
    width: 80%;
    margin: auto;
  }
  .logos {
    padding-top: 30px;
    width: 25%;
    height: 170px;
    text-align: center;
    padding-bottom: 20px;
  }

  .mdi-36px {
    font-size: 30px;
    margin-right: 5px;
  }
  .bgcontainer {
    display: flex;
    /* border-bottom: 1px solid #b6bfcca6; */
    padding-bottom: 40px;
    width: 100%;
    justify-content: space-evenly;
  }
  .appbtn {
    display: flex;
    transition: 0.6s all ease-in-out;
    border: 1px solid #a6a6a6;
    background: #ffebe5 !important;
    color: black;
    width: 150px;
    height: 50px;
    margin: 20px 3px;
  }
  .appbtn:hover {
    display: flex;
    border: 1px solid #a6a6a6;
    background: black !important;
    color: white;
    width: 150px;
    height: 50px;
    margin-left: 16px;
    margin-right: 16px;
  }
  .appstore {
    font-family: "Open Sans", sans-serif;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
  }

  .appsto {
    font-family: "Open Sans", sans-serif;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
  }
  .loginNames {
    padding-top: 30px;
    font-family: "Open Sans", sans-serif;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-size: 18px;
    color: #000000;
  }

  .loginName {
    font-family: "Open Sans", sans-serif;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.3px;
    padding-top: 80px;
  }

  .email_para {
    font-family: "Open Sans", sans-serif;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-size: 16px;
    color: #7e8389;
    padding: 40px 0px;
    letter-spacing: 0.8px;
  }
  .text_para {
    font-family: "Open Sans", sans-serif;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-size: 16px;
    color: #7e8389;
    padding-bottom: 20px;
    letter-spacing: 0.8px;
  }
  .link {
    font-family: "Open Sans", sans-serif;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: bold;
    color: #7e8389;
    letter-spacing: 0.8px;
  }
}
@media screen and (max-width: 500px) and (min-width: 300px) {
  .logos2 {
    display: none;
  }
  .loginBg {
    background-color: #f7f7f7;
    width: 100%;
    height: 100vh;
    align-items: center;
    display: block;
  }
  .loginContainers {
    width: 85%;
    height: 450px;
    background: #ffffff;
    box-shadow: 0px 4px 44px -10px rgba(0, 0, 0, 0.06);
    border-radius: 30px;
    margin: auto;
  }
  .loginBox {
    width: 80%;
    margin: auto;
  }
  .logos {
    padding-top: 30px;
    max-width: 25%;
    max-height: 170px;
    text-align: center;
    padding-bottom: 10px;
  }

  .mdi-36px {
    font-size: 30px;
    margin-right: 5px;
  }
  .bgcontainer {
    display: flex;
    /* border-bottom: 1px solid #b6bfcca6; */
    padding-bottom: 30px;
    width: 100%;
    justify-content: space-evenly;
  }
  .appbtn {
    display: flex;
    transition: 0.6s all ease-in-out;
    border: 1px solid #a6a6a6;
    background: #ffebe5 !important;
    color: black;
    width: 130px;
    height: 50px;
    margin: 20px 3px;
  }
  .appbtn:hover {
    display: flex;
    border: 1px solid #a6a6a6;
    background: black !important;
    color: white;
    width: 130px;
    height: 50px;
    margin-left: 16px;
    margin-right: 16px;
  }
  .appstore {
    font-family: "Open Sans", sans-serif;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
  }

  .appsto {
    font-family: "Open Sans", sans-serif;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
  }
  .loginNames {
    padding-top: 30px;
    font-family: "Open Sans", sans-serif;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-size: 17px;
    color: #000000;
  }

  .loginName {
    font-family: "Open Sans", sans-serif;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.3px;
    padding-top: 80px;
  }

  .email_para {
    font-family: "Open Sans", sans-serif;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-size: 13px;
    color: #7e8389;
    padding: 40px 0px;
    letter-spacing: 0.8px;
  }
  .text_para {
    font-family: "Open Sans", sans-serif;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-size: 13px;
    color: #7e8389;
    padding-bottom: 20px;
    letter-spacing: 0.8px;
  }
  .link {
    font-family: "Open Sans", sans-serif;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: bold;
    color: #7e8389;
    letter-spacing: 0.8px;
  }
}

/* @media screen and (max-width: 1500px) and (min-width: 1200px) {
    .congPics{
        position: relative;
        width: 20%;
    }
    .congPic{
        width: 10%;
        text-align: center;
        position: relative;
        top: 165px;
        left: 19px;
    }
} */

@media screen and (max-width: 1000px) and (min-width: 700px) {
  .logos2 {
    display: none;
  }
  .loginBg {
    background-color: #f7f7f7;
    width: 100%;
    height: 100vh;
    align-items: center;
    display: block;
  }
  .loginContainers {
    width: 70%;
    height: 506px;
    background: #ffffff;
    box-shadow: 0px 4px 44px -10px rgba(0, 0, 0, 0.06);
    border-radius: 30px;
    margin: auto;
  }
  .loginBox {
    width: 70% !important;
    margin: auto;
  }
  .logos {
    padding-top: 30px;
    width: 25%;
    height: 170px;
    text-align: center;
    padding-bottom: 20px;
  }

  .mdi-36px {
    font-size: 30px;
    margin-right: 5px;
  }
  .bgcontainer {
    display: flex;
    /* border-bottom: 1px solid #b6bfcca6; */
    padding-bottom: 40px;
    width: 100%;
    justify-content: space-evenly;
  }
  .appbtn {
    display: flex;
    transition: 0.6s all ease-in-out;
    border: 1px solid #a6a6a6;
    background: #ffebe5 !important;
    color: black;
    width: 180px;
    height: 60px;
    margin: 20px 7px;
    align-items: center;
  }
  .appbtn:hover {
    display: flex;
    border: 1px solid #a6a6a6;
    background: black !important;
    color: white;
    width: 180px;
    height: 60px;
    margin-left: 16px;
    margin-right: 16px;
    align-items: center;
  }
  .appstore {
    font-family: "Open Sans", sans-serif;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
  }

  .appsto {
    font-family: "Open Sans", sans-serif;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
  }
  .loginNames {
    padding-top: 30px;
    font-family: "Open Sans", sans-serif;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-size: 19px;
    color: #000000;
  }

  .loginName {
    font-family: "Open Sans", sans-serif;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 28px;
    letter-spacing: -0.3px;
    padding-top: 80px;
  }

  .email_para {
    font-family: "Open Sans", sans-serif;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-size: 17px;
    color: #7e8389;
    padding: 40px 0px;
    letter-spacing: 0.8px;
  }
  .text_para {
    font-family: "Open Sans", sans-serif;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-size: 17px;
    color: #7e8389;
    padding-bottom: 20px;
    letter-spacing: 0.8px;
  }
  .link {
    font-family: "Open Sans", sans-serif;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: bold;
    color: #7e8389;
    letter-spacing: 0.8px;
  }
}
