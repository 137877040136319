@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
}

.header{
    background-color: #FFFFFF;
    
}

.icon-header{
    position: absolute;
    top: 0;
    left: 2;
    padding: 2rem 0.5rem;
}

.main-b{
    background-color: #DAD9D9;
    padding: 3rem 0;
    height: 100%;
    font-family: 'Montserrat', sans-serif;
}

.container-b{
    width: 35%;
    height: 100%;
    margin: auto;
    background-color: #FFFFFF;
    border: 0 solid transparent;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.detail-h1{
    font-size: 1.5rem;
    font-weight: 700;
    padding: 1.5rem 0 0 1.5rem;
    color: #000000;
}

.tab2 {
    tab-size: 4;
}

.detail-h1-border{
    border-bottom: 2px solid#E4E4E4;
}

.details-main{ 
    padding: 0.6rem 0.8rem 0 1.8rem;
}

.details-h5{
    margin-top: 1.8rem;
    font-size: 1.1rem;
    font-weight: 700;
    margin-bottom: 1.2rem;
    color: #000000;
}

.detail-list-items{
    padding-left: 1rem;
}

.detail-list-items > li{
    margin: 0.5rem 0;
}

.list-title{
    font-weight: 700;
}

@media screen and (max-width: 600px) {
    .container-b{
        width: 95%;
    }
}

.head-tab{
    margin-left: 1rem;
}
.head-tab-1{
    margin-left: 0.5rem;
}

.bold-p{
    font-weight: 700;
}

ul{
    list-style: none;
}

.list-order-1{
    margin-left: 1.5rem;
}
.list-order-2{
    margin-left: 1.9rem;
}
.list-order-25{
    margin-left: 2.2rem;
}
.list-order-27{
    margin-left: 1.7rem;
}
.list-order-3{
    margin-left: 2rem;
}
.list-order-4{
    margin-left: 2.3rem;
}
.list-order-45{
    margin-left: 2.1rem;
}
.list-order-5{
    margin-left: 2rem;
}
.list-order-6{
    margin-left: 3.2rem;
}

  
.list-31::before {
content: "3.1.1 ";
font-weight: 700;
margin-left: -2.5rem;
}

.list-32::before {
content: "3.1.2 ";
font-weight: 700;
margin-left: -2.5rem;

}
.list-33::before {
content: "3.1.3 ";
font-weight: 700;
margin-left: -2.5rem;

}
.list-34::before {
content: "3.1.4 ";
font-weight: 700;
margin-left: -2.5rem;
}
.list-35::before {
content: "3.1.5 ";
font-weight: 700;
margin-left: -2.5rem;
}

.list-36::before {
content: "3.1.6 ";
font-weight: 700;
margin-left: -2.5rem;
}
.list-37::before {
content: "3.1.7 ";
font-weight: 700;
margin-left: -2.5rem;
}
.list-38::before {
content: "3.1.8 ";
font-weight: 700;
margin-left: -2.5rem;
}

.list-61::before {
    content: "6.1 ";
    margin-left: -1.8rem;
    }
.list-62::before {
content: "6.2 ";
margin-left: -1.8rem;
}
.list-63::before {
content: "6.3 ";
margin-left: -1.8rem;
}
.list-64::before {
content: "6.4 ";
margin-left: -1.8rem;
}
.list-65::before {
content: "6.5 ";
margin-left: -1.8rem;
}

.list-71-t::before {
    content: "7.1.1 ";
    margin-left: -3rem;
    }
.list-72-t::before {
content: "7.1.2 ";
margin-left: -3rem;
}
.list-73-t::before {
content: "7.1.3 ";
margin-left: -3rem;
}
.list-74-t::before {
content: "7.1.4 ";
margin-left: -3rem;
}
.list-75::before {
    content: "7.1.5 ";
    margin-left: -3rem;
}
.list-76::before {
    content: "7.1.6 ";
    margin-left: -3rem;
}
.list-77::before {
    content: "7.1.7 ";
    margin-left: -3rem;
}
.list-78::before {
    content: "7.1.8 ";
    margin-left: -3rem;
}
.list-79::before {
    content: "7.1.9 ";
    margin-left: -3rem;
}
.list-710::before {
    content: "7.1.10 ";
    margin-left: -3rem;
}
.list-711::before {
    content: "7.1.11 ";
    margin-left: -3rem;
}
.list-712::before {
    content: "7.1.12 ";
    margin-left: -3rem;
}
.list-713::before {
    content: "7.1.13 ";
    margin-left: -3rem;
}
.list-714::before {
    content: "7.1.14 ";
    margin-left: -3rem;
}
.list-715::before {
    content: "7.1.15 ";
    margin-left: -3rem;
}
.list-716::before {
    content: "7.1.16 ";
    margin-left: -3rem;
}
.list-717::before {
    content: "7.1.17 ";
    margin-left: -3rem;
}
.list-718::before {
    content: "7.1.18 ";
    margin-left: -3rem;
}
.list-719::before {
    content: "7.1.19 ";
    margin-left: -3rem;
}
.list-720::before {
    content: "7.1.20 ";
    margin-left: -3rem;
}
.list-721::before {
    content: "7.1.21 ";
    margin-left: -3rem;
}
.list-722::before {
    content: "7.1.22 ";
    margin-left: -3rem;
}


.list-111::before{
    content: "11.1 ";
    margin-left: -2.2rem;
}
.list-112::before{
    content: "11.2 ";
    margin-left: -2.2rem;
}
.list-113::before{
    content: "11.3 ";
    margin-left: -2.2rem;
}
.list-114::before{
    content: "11.4 ";
    margin-left: -2.2rem;
}
.list-115::before{
    content: "11.5 ";
    margin-left: -2.2rem;
}
.list-116::before{
    content: "11.6 ";
    margin-left: -2.2rem;
}

.list-171::before{
    content: "17.1 ";
    margin-left: -2.2rem;
}
.list-172::before{
    content: "17.2 ";
    margin-left: -2.2rem;
}
.list-173::before{
    content: "17.3 ";
    margin-left: -2.2rem;
}
.list-174::before{
    content: "17.4 ";
    margin-left: -2.2rem;
}
.list-175::before{
    content: "17.5 ";
    margin-left: -2.2rem;
}
.list-176::before{
    content: "17.6 ";
    margin-left: -2.2rem;
}
.list-177::before{
    content: "17.7 ";
    margin-left: -2.2rem;
}

.space-a{
    margin-left: 1.2rem;
}

.list-A::before{
    content: "A.";
    margin-left: -2rem;
}
.list-B::before{
    content: "B.";
    margin-left: -2rem;
}
.list-C::before{
    content: "C.";
    margin-left: -2rem;
}
.list-D::before{
    content: "D.";
    margin-left: -2rem;
}
.list-E::before{
    content: "E.";
    margin-left: -2rem;
}
.list-F::before{
    content: "F.";
    margin-left: -2rem;
}
.list-G::before{
    content: "G.";
    margin-left: -2rem;
}
.list-H::before{
    content: "H.";
    margin-left: -2rem;
}
.list-I::before{
    content: "I. ";
    margin-left: -2rem;
}
.list-J::before{
    content: "J.";
    margin-left: -2rem;
}
.list-K::before{
    content: "K.";
    margin-left: -2rem;
}
.list-L::before{
    content: "L.";
    margin-left: -2rem;
}
.list-M::before{
    content: "M.";
    margin-left: -2rem;
}

.list-a-1::before{
    content: "a. ";
    font-weight: 700;
    margin-left: -1rem;
}
.list-b-1::before{
    content: "b. ";
    font-weight: 700;
    margin-left: -1rem;
}
.list-c-1::before{
    content: "c. ";
    font-weight: 700;
    margin-left: -1rem;
}
.list-d-1::before{
    content: "d. ";
    font-weight: 700;
    margin-left: -1rem;
}
.list-e-1::before{
    content: "e. ";
    font-weight: 700;
    margin-left: -1rem;
}
.list-f-1::before{
    content: "f. ";
    font-weight: 700;
    margin-left: -1rem;
}

.list-a::before{
    content: "a. ";
    margin-left: -1rem;
}
.list-b::before{
    content: "b. ";
    margin-left: -1rem;
}
.list-c::before{
    content: "c. ";
    margin-left: -1rem;
}
.list-d::before{
    content: "d. ";
    margin-left: -1rem;
}
.list-e::before{
    content: "e. ";
    margin-left: -1rem;
}
.list-f::before{
    content: "f. ";
    margin-left: -1rem;
}
.list-g::before{
    content: "g. ";
    margin-left: -1rem;
}
.list-h::before{
    content: "h. ";
    margin-left: -1rem;
}
.list-i::before{
    content: "i. ";
    margin-left: -1rem;
}
.list-j::before{
    content: "j. ";
    margin-left: -1rem;
}
.list-k::before{
    content: "k. ";
    margin-left: -1rem;
}
.list-l::before{
    content: "l. ";
    margin-left: -1rem;
}
.list-m::before{
    content: "m. ";
    margin-left: -1rem;
}
.list-n::before{
    content: "n. ";
    margin-left: -1rem;
}
.list-o::before{
    content: "o. ";
    margin-left: -1rem;
}
.list-p::before{
    content: "p. ";
    margin-left: -1rem;
}
.list-q::before{
    content: "q. ";
    margin-left: -1rem;
}
.list-r::before{
    content: "r. ";
    margin-left: -1rem;
}
.list-s::before{
    content: "s. ";
    margin-left: -1rem;
}
.list-t::before{
    content: "t. ";
    margin-left: -1rem;
}
.list-u::before{
    content: "u. ";
    margin-left: -1rem;
}

.head-space{
    margin-left: 2rem;
}
.head-space-1{
    margin-left: 2.4rem;
}

.head-2::before{
    content: "2 ";
    margin-left: -2rem;
}
.head-3::before{
    content: "3 ";
    margin-left: -2rem;
}
.head-4::before{
    content: "4 ";
    margin-left: -2rem;
}
.head-5::before{
    content: "5 ";
    margin-left: -2rem;
}
.head-7::before{
    content: "7 ";
    margin-left: -2rem;
}
.head-8::before{
    content: "8 ";
    margin-left: -2rem;
}
.head-9::before{
    content: "9 ";
    margin-left: -2rem;
}
.head-10::before{
    content: "10";
    margin-left: -2.5rem;
}
.head-11::before{
    content: "11";
    margin-left: -2.5rem;
}

.head-12::before{
    content: "12";
    margin-left: -2.5rem;
}
.head-13::before{
    content: "13";
    margin-left: -2.5rem;
}
.head-15::before{
    content: "15";
    margin-left: -2.5rem;
}
.head-16::before{
    content: "16";
    margin-left: -2.5rem;
}

.list-21::before{
    content: "2.1 ";
    margin-left: -1.8rem;
}
.list-22::before{
    content: "2.2 ";
    margin-left: -1.8rem;
}
.list-23::before{
    content: "2.3 ";
    margin-left: -1.8rem;
}
.list-24::before{
    content: "2.4 ";
    margin-left: -1.8rem;
}
.list-25::before{
    content: "2.5 ";
    margin-left: -1.8rem;
}
.list-26::before{
    content: "2.6 ";
    margin-left: -1.8rem;
}
.list-27::before{
    content: "2.7 ";
    margin-left: -1.8rem;
}
.list-28::before{
    content: "2.8 ";
    margin-left: -1.8rem;
}
.list-29::before{
    content: "2.9 ";
    margin-left: -1.8rem;
}
.list-210::before{
    content: "2.10 ";
    margin-left: -1.8rem;
}
.list-211::before{
    content: "2.11 ";
    margin-left: -1.8rem;
}
.list-212::before{
    content: "2.12 ";
    margin-left: -1.8rem;
}
.list-213::before{
    content: "2.13 ";
    margin-left: -1.8rem;
}
.list-214::before{
    content: "2.14 ";
    margin-left: -1.8rem;
}

.list-3-1::before{
    content: "3.1 ";
    margin-left: -1.8rem;
}
.list-3-2::before{
    content: "3.2 ";
    margin-left: -1.8rem;
}
.list-3-3::before{
    content: "3.3 ";
    margin-left: -1.8rem;
}
.list-3-4::before{
    content: "3.4 ";
    margin-left: -1.8rem;
}
.list-3-5::before{
    content: "3.5 ";
    margin-left: -1.8rem;
}
.list-3-6::before{
    content: "3.6 ";
    margin-left: -1.8rem;
}

.list-41::before{
    content: "4.1 ";
    margin-left: -1.8rem;
}
.list-42::before{
    content: "4.2 ";
    margin-left: -1.8rem;
}
.list-43::before{
    content: "4.3 ";
    margin-left: -1.8rem;
}
.list-44::before{
    content: "4.4 ";
    margin-left: -1.8rem;
}
.list-45::before{
    content: "4.5 ";
    margin-left: -1.8rem;
}
.list-46::before{
    content: "4.6 ";
    margin-left: -1.8rem;
}
.list-47::before{
    content: "4.7 ";
    margin-left: -1.8rem;
}
.list-48::before{
    content: "4.8 ";
    margin-left: -1.8rem;
}
.list-49::before{
    content: "4.9 ";
    margin-left: -1.8rem;
}

.list-611::before {
    content: "6.1.1 ";
    margin-left: -2.4rem;
}
.list-612::before {
    content: "6.1.2 ";
    margin-left: -2.4rem;
}
.list-613::before {
    content: "6.1.3 ";
    margin-left: -2.4rem;
}
.list-614::before {
    content: "6.1.4 ";
    margin-left: -2.4rem;
}
.list-615::before {
    content: "6.1.5 ";
    margin-left: -2.4rem;
}
.list-616::before {
    content: "6.1.6 ";
    margin-left: -2.4rem;
}

.list-71::before {
    content: "7.1 ";
    margin-left: -1.7rem;
}
.list-72::before {
    content: "7.2 ";
    margin-left: -1.7rem;
}
.list-73::before {
    content: "7.3 ";
    margin-left: -1.7rem;
}
.list-74::before {
    content: "7.4 ";
    margin-left: -1.7rem;
}

.list-91::before {
    content: "9.1 ";
    margin-left: -1.7rem;
}
.list-92::before {
    content: "9.2 ";
    margin-left: -1.7rem;
}
.list-93::before {
    content: "9.3 ";
    margin-left: -1.7rem;
}
.list-94::before {
    content: "9.4 ";
    margin-left: -1.7rem;
}
.list-95::before {
    content: "9.5 ";
    margin-left: -1.7rem;
}
.list-96::before {
    content: "9.6 ";
    margin-left: -1.7rem;
}
.list-97::before {
    content: "9.7 ";
    margin-left: -1.7rem;
}

.list-121::before {
    content: "12.1 ";
    margin-left: -2.4rem;
}
.list-122::before {
    content: "12.2 ";
    margin-left: -2.4rem;
}
.list-1211::before {
    content: "12.1.1 ";
    margin-left: -3rem;
}
.list-1212::before {
    content: "12.1.2 ";
    margin-left: -3rem;
}

.list-1221::before {
    content: "12.2.1 ";
    margin-left: -3rem;
}
.list-1222::before {
    content: "12.2.2 ";
    margin-left: -3rem;
}
.list-1223::before {
    content: "12.2.3 ";
    margin-left: -3rem;
}

.list-141::before {
    content: "14.1 ";
    margin-left: -2.4rem;
}
.list-1411::before {
    content: "14.1.1 ";
    margin-left: -3rem;
}
.list-1412::before {
    content: "14.1.2 ";
    margin-left: -3rem;
}
.list-1413::before {
    content: "14.1.3 ";
    margin-left: -3rem;
}
.list-1414::before {
    content: "14.1.4 ";
    margin-left: -3rem;
}
.list-1415::before {
    content: "14.1.5 ";
    margin-left: -3rem;
}
.list-1416::before {
    content: "14.1.6 ";
    margin-left: -3rem;
}
.list-1417::before {
    content: "14.1.7 ";
    margin-left: -3rem;
}

.list-81::before {
    content: "8.1 ";
    margin-left: -1.8rem;
}
.list-82::before {
    content: "8.2 ";
    margin-left: -1.8rem;
}
.list-83::before {
    content: "8.3 ";
    margin-left: -1.8rem;
}
.list-84::before {
    content: "8.4 ";
    margin-left: -1.8rem;
}